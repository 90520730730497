import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import clsx from "clsx";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import {
  AnnualDonationBackgroundImage,
  BestValuesAnnualDonation,
  MonthlyDonationBackgroundImage,
  RingSubscriptionActivation,
  SubscribeButtonBackgroundImage,
  SubscriptionCheckedIcon,
} from "helpers/enum/constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SLSelector } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import {
  StripeProduct,
  StripeProductList,
} from "utils/slRTKHelper/slAPI.models";
import { SLButton } from "../../slButton";
import "./index.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const fundName = [
  "Help Bring Soul Link to The World",
  "Help Soul Link Build New Meditations",
  "Scholarship Fund",
  "Contribution Fund",
];

const DonationColumn = () => {
  const { data: config } = useSelector(SLSelector.getConfig);
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  const [openDialog, setOpenDialog] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState<StripeProduct[]>([]);
  const [fundingType, setFundingType] = useState<string>(
    "Help Bring Soul Link to The World"
  );
  const [checked, setChecked] = useState<"monthly" | "yearly">();
  const [subscribeLoader, setSubscribeLoader] = useState(false);

  useEffect(() => {
    getSubscriptions();
  }, []);

  const handleSetSubscription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.value as any);
  };

  const getSubscriptions = async () => {
    const makeCallObj = {
      method: "get",
      url: "/subscription/offering/web",
    };
    const response: StripeProductList = (await makeSLAPICall(
      makeCallObj
    )) as any;
    if (response) {
      setSubscriptionList(
        response.data
          .filter(
            (subs) =>
              subs.metadata["show-on-web"] === "yes" &&
              subs.metadata.type &&
              !isNaN(Number(subs.metadata.price)) &&
              !isNaN(Number(subs.metadata.sorting))
          )
          .sort(
            (a, b) => Number(a.metadata.sorting) - Number(b.metadata.sorting)
          )
      );
    }
  };

  const startSubscription = async () => {
    if (!checked || subscribeLoader) {
      return;
    }

    if (userSubscription) {
      setOpenDialog(true);
    } else {
      setSubscribeLoader(true);
      const obj = subscriptionList.find((o) => o.metadata.type === checked);

      if (obj) {
        const payload = {
          receipt:
            typeof obj.default_price === "string"
              ? obj.default_price
              : obj.default_price?.id,
          fundingType: fundingType,
          price: Number(obj.metadata.price),
          cancelUrl: config?.webAppLink + "/subscription-activation",
          successUrl: config?.webAppLink,
          type: checked,
        };
        const makeCallObj: any = {
          method: "post",
          url: "/subscription/subscribe/{platform}",
          urlParams: { platform: "web" },
          payload,
        };
        const response: string = await makeSLAPICall(makeCallObj);
        if (response) {
          setSubscribeLoader(false);
          window.location.href = response;
        }
      }
    }
  };
  return (
    <>
      <Box
        className="donation-column"
        sx={{
          borderTop: { xs: "none", sm: "1px solid rgba(255,255,255, 0.3)" },
          borderBottom: { xs: "none", sm: "1px solid rgba(255,255,255, 0.3)" },
          paddingLeft: { xs: "24px", sm: "80px", md: "50px" },
          marginRight: { xs: "16px", md: "50px" },
          paddingTop: { xs: "0px", sm: "16px", md: "0px" },
          width: { xs: "100%", sm: "90%", md: "450px", lg: "500px" },
        }}
      >
        <Stack
          role="radiogroup"
          sx={{
            width: { xs: "auto", lg: "450px" },
            paddingTop: { xs: "25px", sm: "50px" },
          }}
        >
          {subscriptionList.map((subs) => {
            return (
              <Box
                key={subs.id}
                role="radio"
                aria-selected={checked === subs.metadata.type}
                tabIndex={0}
                className={
                  checked === subs.metadata.type ? "checked-border" : ""
                }
                sx={{
                  width: { xs: "auto", sm: "90%", md: "400px", lg: "450px" },
                  height: "100px",
                  background: `url(${
                    subs.metadata.type === "yearly"
                      ? AnnualDonationBackgroundImage
                      : MonthlyDonationBackgroundImage
                  })`,
                  borderRadius: "10px",
                  marginBottom: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  paddingRight: "0px",
                  marginRight: "0px",
                  cursor: "pointer",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                onClick={() => setChecked(subs.metadata.type)}
                onKeyPress={onEnterOrSpace(() =>
                  setChecked(subs.metadata.type)
                )}
              >
                <Checkbox
                  tabIndex={-1}
                  sx={{ pointerEvents: "none", padding: "20px" }}
                  icon={
                    <Box component="img" src={RingSubscriptionActivation} />
                  }
                  checkedIcon={
                    <Box
                      sx={{ width: "30px", height: "30px" }}
                      component="img"
                      src={SubscriptionCheckedIcon}
                      alt=""
                    />
                  }
                  checked={checked === subs.metadata.type}
                  onChange={handleSetSubscription}
                  value={subs.name}
                />
                <Stack
                  tabIndex={-1}
                  sx={{
                    pointerEvents: "none",
                    color: "white",
                    width: "100%",
                  }}
                >
                  <SLTypography
                    variant="subheadLight"
                    sx={{
                      pointerEvents: "none",
                      lineHeight: { xs: "15px", sm: "27px" },
                      fontSize: { xs: "12px", sm: "14px" },
                    }}
                  >
                    {subs.name}
                  </SLTypography>
                  <SLTypography
                    variant="subheadLight"
                    sx={{
                      pointerEvents: "none",
                      fontSize: { xs: "12px", sm: "14px" },
                    }}
                  >
                    {subs.description}
                  </SLTypography>
                </Stack>

                {subs.metadata.type === "yearly" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      width: "38.2%",
                      pointerEvents: "none",
                    }}
                  >
                    <Box
                      component="img"
                      src={BestValuesAnnualDonation}
                      aria-label="Best Value"
                      sx={{ pointerEvents: "none" }}
                    />
                  </Box>
                ) : null}
              </Box>
            );
          })}
        </Stack>

        <div>
          <FormControl
            sx={{
              background: "white",
              width: { xs: "100%", sm: "90%", md: "400px", lg: "450px" },
              borderRadius: "10px",
              marginTop: "14px",
              border: "none",
            }}
          >
            <InputLabel
              className="fund-i-want-to-support"
              id="fund-i-want-to-support-label"
              sx={{
                fontFamily: "Figtree",
                fontSize: { xs: "14px", sm: "17px" },
                fontWeight: "500",
                color: "#514C54",
                lineHeight: "16PX",
                paddingTop: "5px",
              }}
            >
              The fund I want to support is
            </InputLabel>
            <Select
              labelId="fund-i-want-to-support-label"
              id="fund-i-want-to-support"
              value={fundingType}
              onChange={(e) =>
                setFundingType(
                  Array.isArray(e.target.value)
                    ? e.target.value[0]
                    : e.target.value
                )
              }
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
              sx={{ border: "none", paddingTop: "5.5px" }}
            >
              {fundName.map((name) => (
                <MenuItem key={name} value={name}>
                  <SLTypography
                    className="subscription-input-list"
                    variant="smallBody"
                    sx={{
                      color: "var(--sl-deep-purple)",
                      position: "relative",
                      top: "5px",
                    }}
                  >
                    {name}
                  </SLTypography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Stack
          className="donation-column-button-stack"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "auto", sm: "90%", md: "400px", lg: "450px" },
            paddingBottom: {
              xs: "",
              sm: "40px",
              md: "12.8%",
              lg: "11.9%",
              xl: "52.5px",
            },
          }}
        >
          <SLTypography
            variant="extraSmallBody"
            sx={{
              paddingTop: { xs: "10px", sm: "27px" },
              paddingBottom: { xs: "10px", sm: "26px" },
              color: "white",
              fontSize: { xs: "10px", sm: "12px" },
            }}
            tabIndex={0}
          >
            Empowering us to advance our mission…
          </SLTypography>
          <Button
            sx={{
              fontFamily: "Figtree",
              width: "200px",
              background: `url(${SubscribeButtonBackgroundImage})`,
            }}
            aria-disabled={!checked}
            className={clsx("subscribeBtn", {
              disablebtn: !checked,
            })}
            onClick={startSubscription}
          >
            {!subscribeLoader ? "Subscribe" : <div id="cover-spin" />}
          </Button>
        </Stack>
      </Box>
      <Dialog open={openDialog} sx={{ borderRadius: "10px" }}>
        <Box sx={{ padding: "15px 25px" }}>
          <Stack direction="column" textAlign="left" spacing={1}>
            <DialogContent
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                padding: 0,
                color: "var(--sl-deep-purple)",
              }}
            >
              <SLTypography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  maxWidth: "350px",
                  textAlign: "center",
                }}
              >
                Subscription
              </SLTypography>
              <SLTypography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  maxWidth: "350px",
                  textAlign: "center",
                }}
              >
                Your account is already subscribed. Kindly restore the existing
                subscription.
              </SLTypography>
            </DialogContent>
          </Stack>
          <Box
            sx={{
              marginTop: "3%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SLButton
              sx={{ width: "200px" }}
              variant="contained"
              messageId={"general.ok"}
              onClick={() => setOpenDialog(false)}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DonationColumn;
