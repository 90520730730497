import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import AuthenticationLayout from "components/authenticationLayout";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import { SLLoader } from "components/loader";
import { SLButton } from "components/slButton";
import { InputStandardField } from "components/slTextField";
import { SLTypography } from "components/slTypography";
import { SocialMedia } from "components/social";
import { Formik } from "formik";
import { lang } from "helpers/common/lang";
import { CheckBoxChecked, CheckBoxUnChecked } from "helpers/enum/constants";
import { checkValidity } from "helpers/validations";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Auth } from "utils/authService";
import { deviceId, deviceInfo } from "utils/firebase";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import * as Yup from "yup";
import "./index.css";

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(2, "The name is too short")
    .max(30, "The name is too long")
    .required("Fill the obligatory field, please"),
  email: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z\._\d]{1,}@[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}$/,
      "Invalid email address"
    )
    .email("Invalid email address")
    .required("Fill the obligatory field, please"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
      "Password must contain at least 8 characters, one upper case, lower character and one number."
    )
    .required("Fill the obligatory field, please"),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Fill the obligatory field, please"),
});

const Signup: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: authRegisterData,
    loading: authRegisterLoading,
    error: authRegisterError,
  } = useSelector(SLSelector.postAuthRegister);

  const [checked, setChecked] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [privacyPolicyError, setPrivacyPolicyError] = useState("");
  const [errorDialog, setErrorDialog] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (authRegisterData) {
      navigate("/additionalInfo");
    }
  }, [authRegisterData]);

  useEffect(() => {
    if (authRegisterError) {
      setErrorDialog(true);
      setValidationError(authRegisterError);
    }
  }, [authRegisterError]);

  const continueAsGuest = () => {
    dispatch(
      SLThunk.postAuthGuestLogin.call({
        platform: "web",
        deviceInfo,
        deviceId,
      })
    );
  };

  useEffect(() => {
    const auth = Auth.user.loggedin();
    const signupCompleted = SLStorage.getItem("signupCompleted");
    dispatch(SLThunk.postAuthRegister.reset());

    if (auth && signupCompleted === false) {
      navigate("/additionalInfo");
    } else if (auth) {
      navigate("/app/home");
    }
  }, []);

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmPassword = (): void => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validate = (values: any) => {
    if (checkValidity(values.password, values.email)) {
      // setPasswordError("The password doesn’t confirm to security requirements");
      return true;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  };
  const signUpForm = (values: any) => {
    if (!checked) {
      setPrivacyPolicyError(
        "You need to agree to the Terms and Conditions to proceed"
      );
    } else {
      Auth.guest.logout();
      Auth.user.logout();
      SLStorage.setItem("signupCompleted", false);
      dispatch(SLThunk.getUserProfile.reset());
      dispatch(SLThunk.getSubscription.reset());
      dispatch(
        SLThunk.postAuthRegister.call({
          email: values.email.replaceAll(" ", ""),
          fullName: values.fullName.trimStart(),
          password: values.password,
          confirmPassword: values.confirmPassword,
          fcmToken: deviceId,
          platform: "web",
          deviceInfo: deviceId,
        })
      );
    }
  };

  const initialValues = {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values: any) => {
    if (!validate(values)) {
      return;
    }
    signUpForm(values);
  };

  return (
    <>
      <div className="auth-container">
        <AuthenticationLayout pageTitle="Signup">
          <Grid
            item
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="auth-wrapper"
            maxWidth="500px"
            padding="0 2rem"
            zIndex={2}
          >
            <SLTypography
              variant="heading"
              tabIndex={0}
              aria-level={2}
              title="Create Account"
              messageId="general.createAccount"
              sx={{
                textAlign: {
                  xs: "left !important",
                  sm: "center !important",
                },
              }}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setErrors,
              }) => (
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <TextField
                    onChange={(e: any) => {
                      handleChange(e);
                      setErrors({ fullName: "" });
                    }}
                    onBlur={handleBlur("fullName")}
                    // error={Boolean(touched.fullName && errors.fullName)}
                    helperText={errors.fullName}
                    value={values.fullName.trimStart()}
                    id="fullName"
                    type="text"
                    variant="standard"
                    label="Full Name"
                    title="Full Name"
                    fullWidth
                  />

                  <TextField
                    onChange={(e: any) => {
                      handleChange(e);
                      setErrors({ email: "" });
                    }}
                    helperText={touched.email && errors.email}
                    value={values.email.replaceAll(" ", "")}
                    name="email"
                    id="email"
                    type="text"
                    variant="standard"
                    label="Email"
                    title="Email"
                    fullWidth
                  />

                  <InputStandardField
                    onChange={(e: any) => {
                      handleChange(e);
                      setErrors({ password: "" });
                      setPasswordError("");
                    }}
                    // error={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur("password")}
                    helperText={touched.password && errors.password}
                    value={values.password}
                    inputProps2={{
                      "aria-invalid":
                        touched.password && errors.password ? "true" : "false",
                      "aria-label": "Password, ".concat(
                        touched.password && errors.password
                          ? errors.password
                          : ""
                      ),
                    }}
                    id="password"
                    variant="standard"
                    labelId="Password"
                    title="Password"
                    type={showPassword ? "text" : "password"}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onKeyPress={() => handleClickShowPassword}
                            edge="end"
                          >
                            {!showPassword ? (
                              <VisibilityOffOutlinedIcon
                                sx={{ color: "var(--colorWhite)" }}
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                sx={{ color: "var(--colorWhite)" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <InputStandardField
                    onChange={(e: any) => {
                      handleChange(e);
                      setErrors({ confirmPassword: "" });
                      setConfirmPasswordError("");
                    }}
                    // error={Boolean(
                    //   touched.confirmPassword && errors.confirmPassword
                    // )}
                    onBlur={handleBlur("confirmPassword")}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    value={values.confirmPassword}
                    inputProps2={{
                      "aria-invalid":
                        touched.confirmPassword && errors.confirmPassword
                          ? "true"
                          : "false",
                      "aria-label": "Password, ".concat(
                        touched.confirmPassword && errors.confirmPassword
                          ? errors.confirmPassword
                          : ""
                      ),
                    }}
                    id="confirmPassword"
                    variant="standard"
                    labelId="Confirm Password"
                    title="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmPassword}
                            onKeyPress={() => handleClickConfirmPassword}
                            edge="end"
                          >
                            {!showConfirmPassword ? (
                              <VisibilityOffOutlinedIcon
                                sx={{ color: "var(--colorWhite)" }}
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                sx={{ color: "var(--colorWhite)" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div style={{ display: "flex" }}>
                    <img
                      tabIndex={0}
                      title="checkbox"
                      style={{
                        marginRight: "8px",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      src={checked ? CheckBoxChecked : CheckBoxUnChecked}
                      onClick={() => {
                        setChecked(!checked);
                        setPrivacyPolicyError("");
                      }}
                      onKeyPress={() => {
                        setChecked(!checked);
                        setPrivacyPolicyError("");
                      }}
                    />
                    <FormControl variant="standard" sx={{ cursor: "pointer" }}>
                      <SLTypography
                        tabIndex={0}
                        title="I agree with the Privacy Policy and Terms of Use"
                        variant="extraSmall"
                        onClick={() => {
                          setChecked(!checked);
                          setPrivacyPolicyError("");
                        }}
                        onKeyPress={() => {
                          setChecked(!checked);
                          setPrivacyPolicyError("");
                        }}
                      >
                        <FormattedMessage id="general.agreeWith" />{" "}
                        <a
                          className="redirection-link"
                          href={lang("general.privacy-url")}
                          aria-label={lang("general.privacy")}
                          target="_blank"
                        >
                          <FormattedMessage id="general.privacy" />
                        </a>{" "}
                        <FormattedMessage id="general.and" />{" "}
                        <a
                          className="redirection-link"
                          href={lang("general.terms-url")}
                          aria-label={lang("general.terms")}
                          target="_blank"
                        >
                          <FormattedMessage id="general.terms" />
                        </a>
                      </SLTypography>
                    </FormControl>
                  </div>

                  {privacyPolicyError ? (
                    <SLTypography
                      fontSize="14px"
                      style={{
                        textAlign: "left",
                        color: "var(--colorRed)",
                      }}
                      tabIndex={0}
                    >
                      {privacyPolicyError}
                    </SLTypography>
                  ) : null}
                  <FormControl variant="standard" fullWidth>
                    <Stack
                      spacing={2}
                      sx={{
                        alignItems: "center",
                        marginTop: "7%",
                      }}
                    >
                      <SLButton
                        tabIndex={0}
                        title="Sign Up"
                        type="submit"
                        id="signup-button"
                        sx={{ width: "40%", color: "white !important" }}
                        messageId="onboarding.signup"
                      />
                    </Stack>
                    <SLTypography
                      tabIndex={0}
                      title="or Sign up with"
                      variant="extraSmall"
                      sx={{ marginTop: "5%", textAlign: "center" }}
                      messageId="general.signupWith"
                    />
                  </FormControl>
                </form>
              )}
            </Formik>
            <SocialMedia />
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <SLTypography
                variant="extraSmall"
                title="Already have an account"
                tabIndex={0}
              >
                <FormattedMessage id="general.alreadyAccount" />{" "}
                <Link
                  href="/login"
                  title="Log in"
                  role="link"
                  className="redirection-link"
                >
                  <FormattedMessage id="general.login" />
                </Link>{" "}
                <FormattedMessage id="general.or" />{" "}
                <Link
                  href="javascript::void(0)"
                  role="link"
                  aria-label=" continue as a guest"
                  className="redirection-link"
                  onClick={continueAsGuest}
                >
                  <FormattedMessage id="general.continueasguest" />
                </Link>
              </SLTypography>
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY || ""}
              size="invisible"
            />
          </Grid>
          <SLLoader open={authRegisterLoading} />
        </AuthenticationLayout>
        <ErrorDialogBox
          errorDialog={errorDialog}
          setErrorDialog={setErrorDialog}
          errorMessage={validationError}
          errorHeaderMessage="Oops!"
        />
      </div>
    </>
  );
};

export default Signup;
